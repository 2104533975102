import React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import { Layout } from './src/components/layout'

const wrapPageElement = ({ element, props }) => (
  <PreviewStoreProvider>
    <Layout {...props}>{element}</Layout>
  </PreviewStoreProvider>
)

export default wrapPageElement
