import React from 'react'
import logo from '../images/Quantics_Logo.svg'
import { LogoWrapper } from '../utils/styles'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import background from '../images/footer-background.svg'
import Twitter from '../images/Twitter.svg'
import YouTube from '../images/YouTube.svg'
import LinkedIn from '../images/LinkedIn.svg'
import Mail from '../images/Mail.svg'
import Phone from '../images/Phone.svg'
import Location from '../images/Location.svg'
import LocalizedLink from './localized-link'
import { LocaleContext } from './layout'

const FooterWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`
const FooterLinksWrapper = styled.div({
  ...tw`border-t border-b border-ocean grid grid-cols-1 md:grid-cols-5 gap-4 my-10 py-10`,
  '--tw-border-opacity': '0.15',
  '> div': {
    '> .links-name': {
      ...tw`font-black text-lg pb-4 text-navy`,
    },
    '> a': tw`block text-ocean py-3`,
  },
  '@media (max-width: 768px)': {
    ...tw`my-5 py-8`,
  },
})
const LogoTextWrapper = styled.div({
  ...tw`font-bold py-4 text-teal`,
  '> span': {
    ...tw`md:border-l md:ml-4 md:pl-4 border-ocean text-sm`,
    '--tw-border-opacity': '0.15',
    'letter-spacing': '1.5px',
  },
})
const SocialLink = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  a {
    img {
      width: 24px;
      height: 24px;
    }
  }
`
const ContactWrapper = styled.div({
  ...tw`flex py-2`,
  '> img': {
    width: '24px',
    height: '24px',
    'margin-right': '15px',
  },
})

const Footer = (props) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <FooterWrapper>
      <div tw="container py-10">
        <div tw="md:flex justify-between">
          <div tw="md:flex">
            <LocalizedLink to="/">
              <LogoWrapper>
                <img src={logo} />
              </LogoWrapper>
            </LocalizedLink>
            <LogoTextWrapper>
              <span>{i18n.footer.tagline}</span>
            </LogoTextWrapper>
          </div>
          <div tw="grid grid-cols-3 gap-1 md:gap-8">
            <SocialLink>
              <Link to="https://www.linkedin.com/company/quantics-io/">
                <img src={LinkedIn} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link to="https://www.youtube.com/channel/UC9Qt0olS6u7IEiq8j8aSoeQ">
                <img src={YouTube} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link to="https://twitter.com/Quantics_io">
                <img src={Twitter} />
              </Link>
            </SocialLink>
          </div>
        </div>

        <FooterLinksWrapper tw="md:px-16">
          <div>
            <p className="links-name">{i18n.footer.company}</p>
            <LocalizedLink to="/about-us">{i18n.footer.about_us}</LocalizedLink>
            <LocalizedLink to="/resources">{i18n.footer.resources}</LocalizedLink>
            <LocalizedLink to="/imprint">{i18n.footer.imprint}</LocalizedLink>
          </div>
          <div>
            <p className="links-name">{i18n.menu.industries}</p>
            <LocalizedLink to="/industry/paper-and-packaging">{i18n.dropdown.industries.paper_packaging}</LocalizedLink>
            <LocalizedLink to="/industry/construction-material">{i18n.dropdown.industries.construction_material}</LocalizedLink>
            <LocalizedLink to="/industry/wood-and-wood-processing">{i18n.dropdown.industries.wood_processing}</LocalizedLink>
            <LocalizedLink to="/industry/food-and-beverage">{i18n.dropdown.industries.food_beverage}</LocalizedLink>
            <LocalizedLink to="/industry/chemicals">{i18n.dropdown.industries.chemicals}</LocalizedLink>
            <LocalizedLink to="/industry/pharma">{i18n.dropdown.industries.pharma}</LocalizedLink>
            <LocalizedLink to="/industry/discrete-manufacturing">{i18n.dropdown.industries.discrete_manufacturing}</LocalizedLink>
          </div>
          <div>
            <p className="links-name">{i18n.menu.services}</p>
            <LocalizedLink to="/services">{i18n.footer.services}</LocalizedLink>
            <LocalizedLink to="/partner">{i18n.footer.partner}</LocalizedLink>
          </div>
          <div>
            <p className="links-name">{i18n.menu.solutions}</p>
            <LocalizedLink to="/solutions/forecasting">{i18n.dropdown.solution.forecasting.headline}</LocalizedLink>
            <LocalizedLink to="/solutions/planning">{i18n.dropdown.solution.planning.headline}</LocalizedLink>
            <LocalizedLink to="/solutions/inventory">{i18n.dropdown.solution.inventory.headline}</LocalizedLink>
            <LocalizedLink to="/solutions/integration">{i18n.dropdown.solution.integration.headline}</LocalizedLink>
          </div>
          <div>
            <p className="links-name">Contact</p>
            <ContactWrapper>
              <img src={Phone} /> <span tw="text-navy">+43 1 4350303</span>
            </ContactWrapper>
            <ContactWrapper>
              <img src={Mail} /> <span tw="text-navy">info@quantics.io</span>
            </ContactWrapper>
          </div>
        </FooterLinksWrapper>
        <div tw="md:flex justify-between">
          <span tw="text-sm text-ocean">
            Copyright © {(new Date().getFullYear())} Quantics. All Rights Reserved.
          </span>
          <LocalizedLink to="/privacy-policy">
            <span tw="text-sm text-ocean">Privacy Policy</span>
          </LocalizedLink>
        </div>
      </div>
    </FooterWrapper>
  )
}
export default Footer
