import React, { useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Social from '../components/social'
import { GlobalStyles } from 'twin.macro'
import { GlobalStyle } from '../utils/global-styles'
import i18n from '../../config/i18n'
import Helmet from 'react-helmet'
const LocaleContext = React.createContext(undefined)

const Layout = ({
  children,
  pageContext: { locale, resourceList },
  location,
}) => {
  useEffect(() => {
    window.pipedriveLeadboosterConfig = {
      base: 'leadbooster-chat.pipedrive.com',
      companyId: 7606420,
      playbookUuid:
        locale === 'en-us'
          ? '3f0e04a7-bae8-43b9-aa48-c62714eee116'
          : 'dfc5e5d5-7175-4485-839e-181a8bd6514d',
      version: 2,
    }
    ;(function () {
      var w = window
      if (w.LeadBooster) {
        console.warn('LeadBooster already exists')
      } else {
        w.LeadBooster = {
          q: [],
          on: function (n, h) {
            this.q.push({ t: 'o', n: n, h: h })
          },
          trigger: function (n) {
            this.q.push({ t: 't', n: n })
          },
        }
      }
    })()
  }, [locale])
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <GlobalStyles />
      <GlobalStyle />
      <Helmet>

        {/* Pipedrive */}
        <script async type="text/plain" data-usercentrics="Pipedrive"
          src="https://leadbooster-chat.pipedrive.com/assets/loader.js">
        </script>

        {/* MailerLite */}
        <script type="text/plain" data-usercentrics="MailerLite">
          {`(function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
          var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
          f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
          var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
          _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

          var ml_account = ml('accounts', '2039988', 'j5z0i0g1s2', 'load');
          `}
        </script>

        {/* Google Analytics */}
        <script async type="text/plain" data-usercentrics="Google Analytics"
          src="https://www.googletagmanager.com/gtag/js?id=G-5HGERGZ5Z9">
        </script>
        <script type="text/plain" data-usercentrics="Google Analytics">
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-5HGERGZ5Z9');
            `}
        </script>

      </Helmet>
      <Header location={location} locale={locale} resourceList={resourceList} />
      <Social />
      {children}
      <Footer />
    </LocaleContext.Provider>
  )
}
export { LocaleContext, Layout }
