module.exports = {
  'en-us': {
    default: true,
    path: '/en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: 'Quantics',
    defaultDescription:
      'Supply Chain Planning for the new normal',
    menu: {
      solutions: 'Solutions',
      services: 'Services',
      industries: 'Industries',
      resources: 'Resources',
      about_us: 'About us',
    },
    buttons: {
      meeting: 'Request a meeting',
      video: 'Watch the video',
      discuss: 'Let’s discover new possibilities',
      project: 'Let’s talk about the project',
      learn_more: 'Learn more',
      download_ebook: 'Download Ebook',
      subscribe: 'Subscribe',
    },
    footer: {
      tagline: 'TRULY AGILE, INTEGRATED & RESILIENT SUPPLY CHAIN PLANNING',
      company: 'Company',
      about_us: 'About Us',
      resources: 'Resources',
      imprint: 'Imprint',
      services: 'Professional Services',
      partner: 'Partnership',
      forecasting: 'Forecast Better',
      planning: 'Plan Smarter',
      inventory: 'Optimize Inventory',
    },
    dropdown: {
      solution: {
        value_proposition: 'Make your supply chain truly agile, integrated & resilient.',
        forecasting: {
          headline: 'Predict Demand',
          desc: 'Benefit from best-of-breed and self-adapting forecasting. Boost accuracy!',
        },
        planning: {
          headline: 'Plan Smarter',
          desc: 'Enjoy agile and collaborative planning. Improve continuously!',
        },
        inventory: {
          headline: 'Optimize Inventory',
          desc: 'Optimally balance efficiency & resilience. Adapt to change with ease!',
        },
        integration: {
          headline: 'Integrate with Lightspeed',
          desc: 'Enjoy a fast and cost-efficient implementation.',
        },
      },
      industries: {
        manufacturing: 'Manufacturing',
        paper_packaging: 'Paper & Packaging',
        construction_material: 'Construction Material',
        wood_processing: 'Wood Processing',
        food_beverage: 'Food & Beverage',
        chemicals: 'Chemicals',
        pharma: 'Pharma',
        discrete_manufacturing: 'Discrete Manufacturing',
      },
    },
    consent: {
      headline: 'Let’s talk about cookies.',
      p1:
        'Our website uses cookies mainly for analytical reasons. Cookies help us to to provide you a better experience on our website and tailor our services towards your needs.',
      p2: 'You can learn more about how we use cookies in our',
      link: 'Privacy Policy.',
      accept: 'Accept',
      decline: 'Decline',
    },
    form: {
      title: 'Only one more step.',
      meeting: {
        first_name: 'First name',
        last_name: 'Last name',
        work_email: 'Business E-Mail',
        company_name: 'Company',
        job_title: 'Position',
        message: 'Enter message or question here ...',
        button: 'Request now',
      },
      error: {
        first_name: 'First name is required',
        last_name: 'Last name is required',
        work_email: 'Business E-Mail is required',
        valid_email: 'Invalid E-Mail address',
      },
      message: {
        headline: 'Your request has been sent.',
        desc: 'We will get back in touch with you as soon as possible!',
      },
      notice:
        'By clicking the button, you consent to our sales team using the information you provide to inform you about our products and services.',
    },
    subscribe: {
      headline: 'Subscribe now.',
      desc: "Don't miss any news and get helpful insights.",
      message: {
        headline: 'Thank you!',
        desc:
          'Make sure to add us to your email whitelist to get all insights and tips.',
      },
      email: 'Enter your email now ...',
      button: 'Subscribe',
    },
    resources: {
      most_popular: {
        headline: 'Most popular',
        button: 'Read now',
      },
      show_more: 'Show more',
      search: {
        title: 'What are you looking for?',
        enter: 'Enter your search terms here',
      },
      category: {
        all: 'All',
        blog: 'Blog post',
        infographics: 'Infographics',
      },
    },
    about_us: {
      latest_news: {
        button: 'Read news',
      },
    },
    services: {
      title: 'Our services',
    },
  },
  'de-at': {
    path: '/de',
    locale: 'de-at',
    siteLanguage: 'de',
    ogLang: 'de_AT',
    defaultTitle: 'Quantics',
    defaultDescription:
      'Supply Chain Planung für die neue Normalität',
    menu: {
      solutions: 'Lösungen',
      services: 'Services',
      industries: 'Industrien',
      resources: 'Ressourcen',
      about_us: 'Über uns',
    },
    buttons: {
      meeting: 'Termin vereinbaren',
      video: 'Video ansehen',
      discuss: 'Jetzt Potenziale entdecken',
      project: 'Jetzt Projekt besprechen',
      learn_more: 'Mehr erfahren',
      download_ebook: 'Paper herunterladen',
      subscribe: 'Abonnieren',
    },
    footer: {
      tagline: 'WAHRHAFT AGILE, INTEGRIERTE & RESILIENTE SUPPLY CHAIN PLANUNG',
      company: 'Unternehmen',
      about_us: 'Über Uns',
      resources: 'Ressourcen',
      imprint: 'Impressum',
      services: 'Professional Services',
      partner: 'Partnerschaft',
      forecasting: 'Besser prognostizieren',
      planning: 'Smarter planen',
      inventory: 'Bestand optimieren',
    },
    dropdown: {
      solution: {
        value_proposition: 'Profitieren Sie von einer agilen, integrierten & resilienten Supply Chain.',
        forecasting: {
          headline: 'Nachfrage prognostizieren',
          desc: 'Profitieren Sie von best-of-breed und agilen Prognosen. Erhöhen Sie die Genauigkeit!',
        },
        planning: {
          headline: 'Schlauer planen',
          desc: 'Planen Sie mühelos gemeinsam. Verbessern Sie sich laufend!',
        },
        inventory: {
          headline: 'Bestände optimieren',
          desc: 'Finden Sie die Balance zwischen Effizienz & Resilienz. Passen Sie sich flexibel an Veränderungen an!',
        },
        integration: {
          headline: 'Einfach integrieren',
          desc: 'Genießen Sie eine schnelle und kosteneffiziente Implementierung. Skalieren Sie flexibel!',
        },
      },
      industries: {
        manufacturing: 'Produktion',
        paper_packaging: 'Papier & Verpackung',
        construction_material: 'Baumaterial',
        wood_processing: 'Holzverarbeitung',
        food_beverage: 'Essen & Trinken',
        chemicals: 'Chemikalien',
        pharma: 'Pharma',
        discrete_manufacturing: 'Diskrete Fertigung',
      },
    },
    consent: {
      headline: 'Let’s talk about cookies.',
      p1:
        'Our website uses cookies mainly for analytical reasons. Cookies help us to to provide you a better experience on our website and tailor our services towards your needs.',
      p2: 'You can learn more about how we use cookies in our',
      link: 'Privacy Policy.',
      accept: 'Accept',
      decline: 'Decline',
    },
    form: {
      title: 'Nur noch ein Schritt.',
      meeting: {
        first_name: 'Vorname',
        last_name: 'Nachname',
        work_email: 'Business E-Mail',
        company_name: 'Unternehmen',
        job_title: 'Position',
        message: 'Frage oder Nachricht eingeben ...',
        button: 'Jetzt anfragen',
      },
      error: {
        first_name: 'Vorname ist erforderlich',
        last_name: 'Nachname ist erforderlich',
        work_email: 'Business E-Mail ist erforderlich',
        valid_email: 'Ungültige E-Mail-Adresse',
      },
      message: {
        headline: 'Ihre Anfrage wurde gesendet. ',
        desc: 'Wir melden uns schnellstmöglich bei Ihnen!',
      },
      notice:
        'Durch das Anklicken der Schaltfläche erklären Sie sich damit einverstanden, dass unser Vertriebsteam die von Ihnen bereitgestellten Daten verwendet, um Sie über unsere Produkte und Dienstleistungen zu kontaktieren. ',
    },
    subscribe: {
      headline: 'Jetzt Abonnieren.',
      desc: 'Bleiben Sie auf dem Laufenden und erhalten Sie wertvolle Inhalte.',
      message: {
        headline: 'Vielen Dank!',
        desc:
          'Danke, dass Sie unseren Newsletter abonniert haben! Stellen Sie sicher, dass Sie uns zu Ihrer E-Mail-Whitelist hinzufügen, um alle Tipps und Ratschläge zu erhalten.',
      },
      email: 'Geben Sie jetzt Ihre E-Mail ein ...',
      button: 'Abonnieren',
    },
    resources: {
      most_popular: {
        headline: 'Beliebteste',
        button: 'Jetzt lesen',
      },
      show_more: 'Mehr anzeigen',
      search: {
        title: 'Wonach suchen Sie?',
        enter: 'Geben Sie hier Ihre Suchbegriffe ein',
      },
      category: {
        all: 'Alle',
        blog: 'Blog Beiträge',
        infographics: 'Infografiken',
      },
    },
    about_us: {
      latest_news: {
        button: 'News lesen',
      },
    },
    services: {
      title: 'Unsere Services',
    },
  },
}
