import React from 'react'
import { Global, css } from '@emotion/react'
import Dot from '../images/dot.svg'
import 'animate.css/animate.css'

export const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

      body {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
      }
      :root {
        --animate-duration: 1.5s;
        scroll-padding-top: 70px;
      }
      h1 {
        font-size: 54px;
        font-weight: 900;
        line-height: 66px;
      }
      h2 {
        font-size: 40px;
        font-weight: 900;
        line-height: 48px;
      }
      h3 {
        font-size: 30px;
        line-height: 42px;
        font-weight: 900;
      }
      h4 {
        font-size: 22px;
        font-weight: 900;
        line-height: 26px;
      }
      h5 {
        font-size: 18px;
        font-weight: 900;
        line-height: 22px;
      }
      h6 {
        font-size: 16px;
      }
      
      img {
        z-index: 1;
      }
      
      p > a {
        color: #008BAD;
        font-weight: bold;
        
        &:hover {
          color: #38C3E0;
        }
      }

      // text labels from Prismic
      .turquoise {
        color: #38c3e0;
      }
      .bg-turquoise {
        background: #38c3e050;
        border-radius: 5px;
        padding: 0 5px;
      }
      input,
      textarea {
        font-family: 'Lato', sans-serif;
        color: white;
      }
      input:focus,
      textarea:focus {
        outline: none;
      }
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #1d5a80;
      }
      input::-moz-placeholder,
      textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #1d5a80;
      }
      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: #1d5a80;
      }
      input:-moz-placeholder,
      textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #1d5a80;
      }

      .slick-dots {
        li {
          button:before {
            color: #38c3e0;
            opacity: 1;
          }
        }
        li.slick-active {
          button:before {
            color: transparent;
            background-image: url(${Dot});
            width: 26px;
            height: 26px;
            top: -4px;
            left: -3px;
          }
        }
      }

      .q-select {
        .q__control {
          z-index: 2;
          background: #38c3e0;
          /*
            box-shadow: 0px 19px 29px rgba(0, 126, 159, 0.159601);
            */
          border-radius: 29.5px;
          height: 60px;
          padding: 0 25px;
          .q__single-value {
            color: white;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
          }
        }
        .q__control {
          border-color: #38c3e0;
          box-shadow: 0 0 0 1px #38c3e0;
        }
        .q__control:hover {
          border-color: #38c3e0;
          box-shadow: 0 0 0 1px #38c3e0;
          color: #ffffff;
        }
        .q__control--menu-is-open:hover {
          border-color: #38c3e0;
          box-shadow: 0 0 0 1px #38c3e0;
          color: #ffffff;
        }
        .q__indicators {
          span {
            width: 0px;
          }
          .q__indicator {
            color: white;
          }
        }
        .q__menu {
          top: 40%;
          padding-top: 40px;
          padding: 40px 30px 20px 30px;
          border-radius: 29.5px;
          box-shadow: 0px 19px 29px rgb(0 126 159 / 16%);
          .q__option {
            background: none;
            color: #263c5c;
            padding: 15px 0;
          }
        }
      }
      .button.is-loading {
        color: transparent;
        pointer-events: none;
      }
      .button.is-loading:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: spinAround 1s ease infinite;
      }

      .modal-video {
        background-color: rgba(29, 90, 128, 0.5);
      }

      .consent {
        position: fixed;
        bottom: 53px !important;
        left: 48px;
        background: #ffffff;
        padding: 55px 65px 50px;
        max-width: 565px;
        box-shadow: 0px 2px 25px rgba(29, 33, 72, 0.0975863);
        border-radius: 8px;
        z-index: 100;
        h1 {
          font-weight: 900;
          font-size: 35px;
          line-height: 42px;
          margin-bottom: 25px;
          color: #263c5c;
        }
        p {
          margin-bottom: 25px;
          font-size: 18px;
          line-height: 26px;
          color: #1d5a80;
          a {
            font-weight: 600;
          }
        }
        .consent-buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;
          #rcc-decline-button {
            background: #1d5a80;
            box-shadow: 0px 11px 28px rgba(29, 90, 128, 0.249549);
            border-radius: 4px;
            color: #ffffff;
            font-weight: 900;
            font-size: 22px;
            line-height: 26px;
            padding: 15px 0px;
            width: 190px;
          }
          #rcc-confirm-button {
            background: #38c3e0;
            box-shadow: 0px 11px 28px rgba(29, 90, 128, 0.249549);
            border-radius: 4px;
            color: #ffffff;
            font-weight: 900;
            font-size: 22px;
            line-height: 26px;
            padding: 15px 0px;
            width: 190px;
          }
        }

        @media (max-width: 640px) {
          padding: 25px;
          width: 95%;
          top: 50%;
          right: auto;
          bottom: auto !important;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          left: 50%;
          margin-right: -50%;
          .consent-buttons {
            flex-direction: column;
            #rcc-decline-button {
              width: 100%;
            }
            #rcc-confirm-button {
              width: 100%;
            }
            button {
              margin-bottom: 20px;
            }
          }
        }
      }
      .article-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
      }
      .progress-bar {
        div {
          top: 115px !important;
          background-color: #38c3e0 !important;
          height: 6 !important;
        }

        @media (max-width: 768px) {
          div {
            top: 98px !important;
          }
        }
        @media (max-width: 640px) {
          div {
            top: 84px !important;
          }
        }
      }

      @keyframes spinAround {
        from {
          transform: rotate(0turn);
        }

        to {
          transform: rotate(1turn);
        }
      }
      .button[disabled] {
        opacity: 0.6;
      }

      @media (max-width: 640px) {
        h1 {
          font-size: 40px;
          font-weight: 900;
          line-height: 48px;
        }
        h2 {
          font-size: 30px;
          font-weight: 900;
          line-height: 42px;
        }
        h3 {
          font-size: 22px;
          line-height: 26.4px;
        }
        h4 {
          font-size: 18px;
        }
        h5 {
          font-size: 16px;
        }
        h6 {
          font-size: 14px;
        }
      }
    `}
  />
)
