import React from 'react'
import tw, { styled } from 'twin.macro'
import FormBackground from '../images/form-base.svg'

export const LogoWrapper = styled.div`
  img {
    width: 176px;
    height: 57px;
  }
  @media (max-width: 640px) {
    img {
      width: 132px;
      height: 43px;
    }
  }
`
export const QForm = styled.div({
  ...tw`mt-8 md:mt-0 px-6 pt-6 pb-10 text-center text-white md:px-12 md:pt-10 md:pb-12 md:text-left rounded-lg`,
  'background-image': `url(${FormBackground})`,
  'background-repeat': 'no-repeat',
  'background-size': 'cover',
  '> form': {
    '> h3': {
      ...tw`mb-4`,
    },
    '> .q-input': {
      ...tw`px-5 text-ocean rounded`,
      'margin-bottom': '15px',
      height: '73px',
      '@media (max-width: 768px)': {
        height: '52px',
      },
    },
    '> .q-textarea': {
      ...tw`px-5 text-ocean rounded`,
      'margin-bottom': '15px',
      'padding-top': '25px',
      'padding-bottom': '25px',
      height: '146px',
      '@media (max-width: 768px)': {
        height: '102px',
      },
    },
  },
})

export const SubForm = styled.div({
  ...tw`px-6 pt-6 pb-10 text-center text-white md:px-12 md:pt-10 md:pb-12 md:text-left rounded-lg bg-ocean`,
  '> form': {
    '> h3': {
      ...tw`mb-5 md:mb-7`,
    },
    '> .q-input': {
      ...tw`px-5 text-ocean rounded text-center`,
      'margin-bottom': '15px',
      height: '52px',
    },
  },
})

export const SmallSolidTealButton = styled.button({
  ...tw`bg-teal text-white text-lg py-3.5 px-6 rounded font-black hover:bg-teal_light`,
  'box-shadow': '0px 11px 28px rgba(29, 90, 128, 0.249549)',
})
export const LargeSolidTealButton = styled.button({
  ...tw`bg-teal text-white text-xl py-5 rounded w-full font-black hover:bg-teal_light`,
  'box-shadow': '0px 11px 28px rgba(29, 90, 128, 0.249549)',
})
export const LargeSolidTurquoiseButton = styled.button({
  ...tw`bg-turquoise text-white text-xl py-5 rounded w-full font-black hover:bg-turquoise_light`,
  'box-shadow': '0px 11px 28px rgba(29, 90, 128, 0.249549)',
})
export const LargeOutlineTealButton = styled.button({
  ...tw`text-teal text-lg py-5 rounded w-full border border-teal font-bold`,
})
export const SmallSolidTurquoiseInput = styled.button({
  ...tw`bg-turquoise text-white text-lg md:text-xl py-3.5 px-6 rounded font-black hover:bg-turquoise_light`,
  'box-shadow': '0px 11px 28px rgba(29, 90, 128, 0.249549)',
})
